import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from "react-helmet"
import { Link, withPrefix } from "gatsby"
import ThankYou from '../components/ThankYou'


export const FormPageTemplate = ({ title, formJsFile, formId, formSrc, formClass, content, contentComponent }) => {
  const PageContent = contentComponent || Content


  if (title==="Effective&trade;Food Service Programs") {
    title = <h2 className="title is-size-3 has-text-weight-bold is-bold-light">EFFECTIVE<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span> Food Service Programs</h2>;
  }
  if (title==="EFFECTIVE&trade; Certification Program") {
    title = <h2 className="title is-size-3 has-text-weight-bold is-bold-light">EFFECTIVE<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span> Certification Program</h2>;
  }

  if (title==="Immunity Wellness Program") {
    title = <h2 className="title is-size-3 has-text-weight-bold is-bold-light">IMMUNITY<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span> Wellness Program</h2>;
  }

  else {
    title = <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2>;;
  }



  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section" style={{padding: "0px", paddingBottom: "0px", marginLeft: "-15px"}}>
                {title}
              <ThankYou/>
              <PageContent className="content" content={content}/>
              <div align="center">
              <Helmet>
                <script src={withPrefix('{formJsFile}')} type="text/javascript" />
              </Helmet>

              {formClass==="paypal" &&

              <div>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick"/>
              <input type="hidden" name="hosted_button_id" value="FX4NL77FEJYGJ"/>
              <table style={{padding: "10px", fontSize: "16px"}}>
              <tr><td><input type="hidden" name="on0" value="Certification Type"/><b>Health Claim Certification Type:&nbsp;&nbsp;</b></td><td><select name="os0" style={{width: "120px", fontSize: "16px"}}>
              	<option value="Immunity">Immunity </option>
              	<option value="Low Carb">Low Carb </option>
              </select> </td></tr>
              </table>
              <input type="image" src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" style={{padding: "10px"}}/><br/>
              <img alt="" border="0" src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppmcvdam.png"/>
              </form>

              </div>

              }

              {formClass==="ef-form-report-effectiveness-immunity" &&

                          <div style={{textAlign: 'center', backgroundColorx: "#ff0000", position: "relative", top:"-10px"}} className="column is-3x ef-button-report-effectivenessx">
                          <div><b>If you have not enrolled yet, please enroll by clicking the button below before reporting effectiveness.</b></div>
                          <Link className="btn" to="/forms/enroll-tester" style={{marginTop: "20px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#00693eff", color: "white"}}>
                          Become a Tester
                          </Link>
                          </div>

              }

              {formClass==="ef-form-report-effectiveness-immunity" &&
              <div style={{textAlign: "left",paddingTop: "20px"}}><h2 className="title is-size-5 has-text-weight-bold is-bold-light">Please Use the Symptoms Severity Definitions Below to Report:</h2>
              <p style={{paddingBottom: '10px'}}><b>No Symptoms:</b><br></br>You tested positive or confident you are infected but have no symptoms.<br></br></p>
              <p style={{paddingBottom: '10px'}}><b>Mild:</b><br></br>You had any of the various signs and symptoms of COVID 19 (e.g., fever, cough, sore throat, malaise, headache, muscle pain) <b>without</b> shortness of breath, difficulty breathing, or abnormal chest imaging.<br></br></p>
              <p style={{paddingBottom: '10px'}}><b>Moderate:</b><br></br>You had evidence of lower respiratory disease by clinical assessment or imaging and a saturation of oxygen (SpO2) ≥94% on room air at sea level.</p>
              <p style={{paddingBottom: '10px'}}><b>Severe:</b><br></br>You had respiratory frequency &gt;30 breaths per minute, SpO2 &lt;94% on room air at sea level, ratio of arterial partial pressure of oxygen to fraction of inspired oxygen (PaO2/FiO2) &lt;300 mmHg, or lung infiltrates &gt;50%.</p>
              <p style={{paddingBottom: '10px'}}><b>Critical:</b><br></br>You had respiratory failure, septic shock, and/or multiple organ dysfunction.</p>
              </div>
            }

{formClass==="ef-form-report-effectiveness-immunity" &&
            <iframe id="JotFormIFrame" title="Report Effectiveness" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src={formSrc} frameborder="0" class="ef-form-report-effectiveness-immunity" scrolling="no"></iframe>
}
{formClass==="ef-form-tester" &&
              <iframe onload="window.parent.scrollTo(0,0)" id="JotFormIFrame" class="ef-form-tester" title="Information Request Form" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src={formSrc} frameborder="1" scrolling="no"></iframe>
}
{formClass==="ef-form-comment" &&
              <iframe onload="window.parent.scrollTo(0,0)" id="JotFormIFrame" class="ef-form-comment" title="Information Request Form" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src={formSrc} frameborder="1" scrolling="no"></iframe>
}
{formClass==="ef-volunteer-form-iframe" &&
              <iframe onload="window.parent.scrollTo(0,0)" id="JotFormIFrame-200967594498171" class="ef-volunteer-form-iframe" title="Information Request Form" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src={formSrc} frameborder="1" scrolling="no"></iframe>
}
{formClass==="ef-food-service-form-iframe" &&
              <iframe onload="window.parent.scrollTo(0,0)" id="JotFormIFrame-201578966921164" class="ef-food-service-form-iframe" title="Information Request Form" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src={formSrc} frameborder="1" scrolling="no"></iframe>
}
{formClass==="ef-wellness-form-iframe" &&
              <iframe onload="window.parent.scrollTo(0,0)" id="JotFormIFrame-201578826091157" class="ef-wellness-form-iframe" title="Information Request Form" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src={formSrc} frameborder="1" scrolling="no"></iframe>
}
















              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FormPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  formJsFile: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formSrc: PropTypes.string.isRequired,
  formClass: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const FormPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <FormPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        formJsFile={post.frontmatter.formJsFile}
        formId={post.frontmatter.formId}
        formSrc={post.frontmatter.formSrc}
        formClass={post.frontmatter.formClass}
        content={post.html}
      />
    </Layout>
  )
}

FormPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FormPage

export const formPageQuery = graphql`
  query FormPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
        formJsFile
        formId
        formSrc
        formClass
      }
    }
  }
`
